<template>
  <div class="main">
    <div id="auth-nav" color="#330063" dense dark>
      <!-- <div-nav-icon></div-nav-icon> -->

      <a href="/colombomail">
        <v-toolbar-title class="white--text"> Colombo Mail </v-toolbar-title>
        <!-- <img src="@/assets/home/ColomboLogo.svg" alt="logo" /> -->
      </a>
      <v-spacer></v-spacer>

      <v-btn color="#4E1E7A" class="white--text register-btn" text to="/register"
        >Register</v-btn
      >
      <v-btn color="#4E1E7A" class="white--text login-btn" text to="/login">Login</v-btn>
      <v-btn color="#4E1E7A" class="white--text" to="/colombomail"
        >Go to home</v-btn
      >
    </div>
    <!-- {{ calcData.countries }} -->
    <section class="content">
      <div class="card">
        <div class="text">
          <div class="shipment-details">
            <h1>Ship from Srilanka to</h1>
            <div class="inputs">
              <div class="input mr-3">
                <v-autocomplete
                  solo
                  dense
                  required
                  v-model="selectedTo"
                  req
                  :items="calcData.countries"
                  label="To"
                  item-text="name"
                  @change="feeCalculate"
                  return-object
                >
                </v-autocomplete>
              </div>
              <div class="input">
                <v-text-field
                  class="input-field"
                  solo
                  dense
                  type="number"
                  v-model="weight"
                  label="Weight in Grams"
                  required
                  min="10"
                  max="30000"
                  suffix="Grams"
                  @keyup="feeCalculate"
                ></v-text-field>
              </div>
            </div>
          </div>
        </div>
        <div class="brands" v-if="Courier.length > 0">
          <div class="brand brand-our">
            <p class="title">
              <span class="blink-circle"></span>
              <span v-html="ourCourier.name"></span>
            </p>
            <div class="export-img">
              <img :src="ourCourier.img" />
            </div>
            <div class="des">
              <h3>LKR {{ ourCourier.rate }}</h3>
              <p>Duration : {{ ourCourier.transit }}</p>
            </div>
          </div>

          <div class="brand" v-for="(item, index) in otherCourier" :key="index">
            <p class="title">
              <span class="blink-circle"></span>
              <span v-html="item.name"></span>
            </p>
            <div class="export-img">
              <img :src="item.img" />
            </div>
            <div class="des">
              <h3>LKR {{ item.rate }}</h3>
              <p>Duration : {{ item.transit }}</p>
            </div>
          </div>
        </div>
        <div
          style="text-align: center"
          class="details-card"
          v-if="Courier.length < 1"
        >
          To ship {{ weight }} grams to {{ selectedTo.name }} <br />
          Contact info@colombomail.lk <br />
          Hotline: (+94) 755 192 192
        </div>

        <div class="details-card" v-else>
          <div
            class="detail-items"
            v-for="(item, index) in Courier"
            :key="index"
          >
            <img :src="item.img" />
            <p>{{ item.description }}</p>
          </div>
        </div>
        <div class="tos">
          <h1>Terms and Conditions</h1>
          <ol class="ol-list">
            <li>
              These conditions of carriage EXCLUDE LIABILITY on the part of
              Colombo Mail and its employees or agents for loss, damage and
              delay in certain circumstances, LIMIT LIABILITY to stated amounts
              where liability is accepted and REQUIRE NOTICE OF CLAIMS within
              strict time limits. Senders should note these conditions carefully
              and where necessary obtain insurance cover in order to protect
              their interests.
            </li>
            <li>
              To continue providing uninterrupted services to our valued
              customers with best possible service during this Covid-19 pandemic
              time, a temporary surcharge will be added on all international
              parcel, freight, and shipments until further notice.
            </li>
            <li>
              Rates and service quotations by employees and agents of Colombo
              Mail will be based upon information provided by the sender but
              final rates and service may vary based upon the shipment actually
              tendered and the application of these conditions. Colombo Mail is
              not liable for, nor will any adjustment, refund or credit of any
              kind be made, as a result of any discrepancy in any rate or
              service quotation made prior to the tender of the shipment and the
              rates, and other charges invoiced to the customer.
            </li>
            <li>
              Charges may be assessed based on dimensional weight. Dimensional
              weight is determined by multiplying a package’s length x height x
              width (all in centimetres) and dividing by 5000 or such other
              number as specified by Colombo Mail from time to time on fedex.com
              If the result exceeds the actual weight, additional charges may be
              assessed based on the dimensional weight.
            </li>
            <li>
              There is no limit on the aggregate weight of a multiple piece
              shipment provided each individual package within the shipment does
              not exceed the per package weight limit specified for the
              destination. For the bulk shipments require advance arrangement
              with Colombo Mail. Details are available upon request.
            </li>
            <li>
              The following items are not acceptable for carriage to any
              destination at any circumstance unless otherwise agreed to by
              Colombo Mail.
              <ul class="ul-list">
                <li>
                  Money (coins, cash, currency paper money and negotiable
                  instruments equivalent to cash such as endorsed stocks, bonds
                  and cash letters).
                </li>
                <li>
                  Explosives fireworks and other items of an incendiary or
                  flammable nature.
                </li>
                <li>
                  Human corpses, organs or body parts, human and animal embryos,
                  cremated or disinterred human remains.
                </li>
                <li>Firearms, weaponry, ammunition and their parts.</li>
                <li>
                  Foodstuffs, perishable food articles and beverages requiring
                  refrigeration or other environmental control.
                </li>
                <li>
                  Hazardous waste, including, but not limited to, used
                  hypodermic needles and/or syringes or medical waste.
                </li>
                <li>
                  Shipments requiring to obtain any special license or permit
                  for transportation, importation or exportation.
                </li>
                <li>
                  Shipments the carriage, importation or exportation of which is
                  prohibited by any law, statute or regulation.
                </li>
                <li>
                  Packages that are wet, leaking or emit an odor of any kind.
                </li>
                <li>Packages that are wrapped in kraft paper.</li>
              </ul>
            </li>
            <li>
              Each package within a shipment must be legibly and durably marked
              with the full name and complete postal address with the PIN code
              and telephone number of both the shipper and the recipient.
              Colombo Mail shall not be liable for non-delivery on account of
              incomplete or erroneous address being furnished by the shipper.
              Further, customer is fully liable to inform us if any erroneous in
              tracking number not later than twenty-four (24) hours since
              receive the tracking number.
            </li>
            <li>
              Colombo Mail reserves the right to add Rs.300 for register post
              charge and our service surcharges on shipments without notice when
              customer uses the Sri Lankan post. In addition, Sri Lankan post
              terms & condition shall be imposed.
            </li>
            <li>
              Rates will be updated in each month based on US dollar and Sri
              Lanka rupee conversion.
            </li>
            <li>
              Shipments through express courier service will be dispatched on
              same day and tracking number will be updated on same day.
              Similarly, shipments through Singapore post and Swiss post will be
              dispatched on Friday and tracking number will be updated on
              following Wednesday.
            </li>
            <li>
              Save as expressly set out herein, Colombo Mail makes no
              warranties, express or implied.
            </li>
            <li>
              Maximum liability for lost/damage/shortage is USD 100 only or
              Invoice value, which ever is lower
            </li>
            <li>
              The above mentioned price may vary day by day depending on the
              exchange rate
            </li>
            <li>
              Kindly note that if the shipments to USPS, Singapore Post (Smart & Simple) and Hermes did not have
              a progress in the tracking during the said time frame, the
              compensation will be provided only for the shipping charge.
            </li>
          
            <li>Non commercial items such as consumables and herbal remedies has high clearance risk in certain countries like Australia and Japan. Hence, an extra vigilance and support from the receiver end will be required.</li>
            <li> An additional charge will be applicable for large packages & shipments exceeding either: weight/length & dimension. Maximum Weight and Size Limits (per piece): Weight 70kg, Length 274cm, Size 419cm in length and girth ((2x width) + (2 x height)) combined</li>
            <li>
               Colombo Mail is not liable for any shipments that is been held at the destination country due to customs inspections and releasing them. Also, the charges shown in the calculator is only the shipping fee and does not include any duty charges or any other charges enforced in the destination country. These charges needs to be bared by the receiver of the parcel.
            </li>
          </ol>
        </div>
      </div>
    </section>
    <div class="footer-credits">
      Made With 💖 By
      <a href="https://www.vantageflow.in/" target="_blank">vantageflow.in</a>
    </div>

    <!-- <v-layout row wrap>
      <v-flex xs="2">
        <v-btn @click="togglePopup" class="side-tab orange" fab small>
          <span class="vertical-text" color="white">Attention!</span>
        </v-btn>
      </v-flex>
    </v-layout> -->
    <!-- <transition name="popup-transition">
      <div v-if="showPopup" class="popup-container">
        <v-card class="popup-card">
          <v-btn
            icon
            @click="closePopup"
            x-small
            fab
            class="close-button mt-1 mr-1 error"
          >
            <v-icon color="white " class="close-button-icon">mdi-close</v-icon>
          </v-btn>
          <img :src="popupImage" alt="Popup Image" style="width: 100%" />
        </v-card>
      </div>
    </transition> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "calculateInfo-id",
  computed: {
    ...mapGetters(["calcData", "allExportServices"]),
    ourCourier() {
      return this.Courier[0];
    },
    otherCourier() {
      return this.Courier.slice(1, 8);
    },
  },
  created() {
    this.init();
  },

  data() {
    return {
      selectedFrom: null,
      selectedTo: null,
      weight: null,
      currencyCost: null,
      Courier: [],
      fetched: false,

      showPopup: false,
      popupImage:
        "https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/staticImages%2FOptimized-attention-1.jpg?alt=media&token=cb3d3530-eae9-48ff-a43e-e287d38f8567",
    };
  },
  methods: {
    ...mapActions([
      "caculateExportPrice",
      "fetchallExportServices",
      "fetchCalculationData",
    ]),

    async init() {
      // this.showPopup = true;
      this.attentionTimer();
      await this.fetchallExportServices();
      await this.fetchCalculationData();
      this.selectedFrom = this.$route.params.selectedFrom || "Srilanka";
      this.selectedTo = this.$route.params.selectedTo || {
        code: "IN",
        name: "India",
        zone: 1,
      };
      this.weight = this.$route.params.selectedWeight || 500;
      await this.feeCalculate();
    },

    attentionTimer() {
      this.showPopup = true;
      setTimeout(() => {
        this.showPopup = false;
      }, 3000); // 5 seconds timeout
    },

    // closePopup() {
    //   this.showPopup = false;
    // },

    closePopup(event) {
      if (!event.target.closest(".pop") && this.showPopup) {
        this.showPopup = false;
      }
    },

    togglePopup() {
      this.showPopup = !this.showPopup;
    },

    async feeCalculate() {
      this.Courier = [];
      const info = {
        from: this.selectedFrom,
        to: this.selectedTo,
        weight: this.weight,
      };
      console.log("info", info);

      await this.caculateExportPrice({
        courierId: null,
        to: this.selectedTo,
        weight: this.weight,
      })
        .then((result) => {
          console.log("result", result);
          console.log("this.result", result);
          result.forEach((item) => {
            if (item.id === "colomboBulk") {
              let namee = item.name 
              let highlightedName= namee.replace(
              /\(Non-Commercial Items Only\)/,
              '<br><span style="background-color: #ffe600; font-size:12px; padding:5px; border-radius:5px; " >(Non-Commercial Items Only)</span>'
            );
              console.log("colomboBulk name",highlightedName)
              item.name = highlightedName
            }
          });
          this.Courier = result;
          console.log("this.courier", this.Courier);

          this.fetched = true;
        })
        .catch((error) => {
          console.error("error", error);
          this.fetched = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.popup-transition-enter-active,
.popup-transition-leave-active {
  transition: all 0.5s ease;
}

.popup-transition-enter {
  opacity: 0;
  transform: translate(100%, 100%);
}

.popup-transition-leave-to {
  opacity: 0;
  transform: translate(100%, 100%);
}

.popup-container {
  position: fixed;
  bottom: 5px;
  right: 42px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 9999;
}

.side-tab {
  position: fixed;
  bottom: 5px;
  right: 0;
  height: 110px;
  background-color: #d80515 !important;
  transform-origin: top right;
  z-index: 9999;
}


.vertical-text {
  display: block;
  color: white;
  transform: rotate(-90deg);
  white-space: nowrap;
}

.popup-card {
  max-width: 500px;
}

.close-button {
  position: absolute;
  top: 0 ;
  right: 0 ;
  z-index: 2;
}

.footer-credits {
  background-color: #070021;
  text-align: center;
  color: #ffffff;
  width: 100vw;
  padding: 5px 0px;
}
.main {
  font-family: "Roboto", sans-serif;
  height: 100%;
  min-height: 100vh;
  color: #000;
  padding-bottom: 0.5em 0.5em 0em 0.5em;
  background: linear-gradient(250.61deg, #0c046c 4.47%, #bb4d55 103.2%);
}

a {
  text-decoration: none;
}

#auth-nav {
  height: 8vh;
  width: 100vw;
  background-color: #330063;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 0px 40px;
}
.shipment-details {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .inputs {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .input {
    width: 250px;
  }
}

.content {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
}

.card {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(74px);
  -webkit-backdrop-filter: blur(0px) saturate(0%);
  border-radius: 12px;
  height: 100%;
  width: 90%;
  min-height: 80vh;
  padding: 20px;
}

.text {
  text-align: center;
  line-height: 1.6;
  z-index: 1;
  color: white;
}

.text h1 {
  font-size: 1.8rem;
  font-weight: 400;
}

.brands {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 0px 10px;
}

.brand-our {
  .export-img img {
    border-radius: 20px;
  }
  .blink-circle {
    width: 20px;
    height: 20px;
    padding: 0px 12px;
    margin-right: 10px;
    border-radius: 500px;
    background-color: rgb(255, 0, 0);
    color: rgb(255, 0, 0);
  }
  
  h3 {
    background-color: #ffe600;
    width: auto;
    color: #0a0a0a;
    padding: 0px 10px;
  }
}
// @keyframes blinker {
//   50% {
//     opacity: 0;
//   }
// }
.brand {
  display: flex;
  margin-top: 10px;
  background: #fff;
  border-radius: 12px;
  margin-right: 10px;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  width: 290px;
}

.title {
  text-align: center;
}

.export-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.export-img img {
  width: 200px;
  height: 100px;
  object-fit: contain;
}

.text p {
  font-size: 1rem;
}

.des h3 {
  text-align: center;
  font-size: 21px;
  text-align: center;
  margin-top: 10px;
}

.des p {
  text-align: center;
  font-size: 15px;
  text-align: center;
  color: black;
  font-weight: 600;
}

.p1 {
  img {
    width: 250px;
  }
}
.calculate-btn-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.details-card {
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 20px auto;
  background: #fff;

  .detail-items:nth-child(1) {
    p {
      background-color: #f1ee12;
      width: auto;
      padding: 0px 10px;
    }
  }

  .detail-items {
    width: 100%;
    max-width: 400px;
    height: 200px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    background: #ffffff;
    /* Single-shadow-sm-2 */
    box-shadow: 0px 2px 4px rgba(14, 31, 53, 0.12);
    border-radius: 12px;
    margin: 5px;

    img {
      width: 150px;
      padding: 20px;
    }

    p {
      width: 50%;
      padding: 10px;
    }
  }
}

.tos {
  width: 100%;
  background: #fff;
  border-radius: 12px;
  padding: 40px;

  li {
    margin: 10px;
    color: rgb(63, 60, 60);

    p {
      font-size: 15px;
      line-height: 1.6;
    }
  }

  ul {
    margin: 20px 40px;
  }

  h1 {
    font-size: 2rem;
    text-align: center;
    color: rgb(146, 46, 146);
    text-transform: uppercase;
  }
}

// NOTE Media Querry
@media (max-width: 786px) {
  main {
    height: 130vh;
  }

  .register-btn{
    display: none;
  }

  .login-btn{
    display: none;
  }

 .popup-card {
  max-width: 300px !important;
}

.close-button {
  padding: 0px !important;
  margin: 0px !important;
  background: transparent !important;
  // width: 20px;
  // height: 15px;
}

.close-button-icon{
  color: red !important;
  // background-color: red !important;
  padding-left: 10px !important;
  padding-top: 0px !important;
}

  .card {
    height: 100%;
    width: 100%;
  }

  .brand {
    margin-bottom: 10px;
  }

  .brands {
    justify-content: center;
  }

  .text h1 {
    font-size: 21px;
  }

  .des h3 {
    margin-top: 5px;
    font-size: 18px;
  }

  .des p {
    margin-top: 5px;
    font-size: 14px;
    text-align: center;
  }
}
</style>